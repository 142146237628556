.admin_components-title {
    background-color: yellow;
    font-size: 3rem;
    color: red;
    padding: 24px;
    margin: auto
}

.product {
    width: 100%;
    font-size: 1.5rem;
}

.product-title {
    background-color: yellow;
    font-size: 3rem;
    color: red;
    padding: 24px;
    margin: auto
}

.admin-home_page-picture-content .admin-home_page-picture-content-input_link {
    font-size: 1.3rem;
    color: blue
}

.product_show_detail-content {
    font-size: 1.3rem;
    color: blue
}

.product_show_detail-content .product_show_detail-content-item {
    width: 200px;
    min-width:200px;
    max-width: 200px;
    height: 180px;
    min-height:180px;
    max-height: 180px;
    color: blue
}