.list-menu-item {
    border: 1px solid;
    width: 200px;
    list-style: none;
    cursor: pointer;
    font-size: 2rem;
    padding: 8px;
    font-weight: 600;
}

.list-menu-item.active {
   background-color: yellow;
}

.admin-home_page {
    margin-top: 24px;
    background-color: rgb(211, 212, 212);
}

.admin-content-title{
    font-weight: 600;
    padding: 8px;
    font-size: 1.8rem;
    background-color: aqua;
}

.admin-home_page-picture-content-item {
    width: 400px;
    height: 300px;
    padding: 8px;
}